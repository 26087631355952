import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import HeaderSEO from '../../components/partials/HeaderSEO'

import Layout from '../../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

const lensesDownloadOk = () => {
    const data = useStaticQuery(graphql`
        query FormBoxSubmittedSEO {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "Form_BoxSubmitted" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node
    return (
        <Layout>
            <HeaderSEO pageSEO={pageSEO} />
            <Helmet>
                <meta name='robots' content='noindex' />
                <meta name='robots' content='nofollow' />
            </Helmet>
            <StaticImage
                className='home-fixed-top'
                src='../../resources/images/homepage/hero_lens.svg'
                placeholder='blurred'
                alt='Hero Lenses'
            />
            <section className='pt-5 mt-5 pb-4 '>
                <div className='container-1 pb-5 pt-5 '>
                    <div className='row mt-3 pb-5'>
                        <div className='col-md-8 col-12 offset-md-2 text-center pt-0 pb-0 mobile-margin-top'>
                            <StaticImage
                                className='img-fluid w-25 mx-auto d-block'
                                src='../../resources/images/book-a-call.svg'
                                placeholder='tracedSVG'
                                alt='download ok'
                            />
                            <h1 className='mt-3 mb-5'>Schedule a demo, start your free trial</h1>
                            <div className='hero-description-dark f-20'>
                                <p>
                                    Book a 20 minute call with a Lenses specialist to give you a
                                    guided tour of the product and collect your trial key:
                                </p>
                                <div
                                    className='roll-button navbar-dl-button mobile-padding-left mobile-margin-top-half'
                                    style={{ margin: '20px 0' }}>
                                    <a
                                        id='navbar-download'
                                        style={{ fontSize: '1rem' }}
                                        className='text-white mr-0 bg-red'
                                        target={'_blank'}
                                        href='https://lenses.chilipiper.com/book/me/sandra-czajkowska1?type=my-meeting'
                                        rel='noreferrer'>
                                        <span data-hover='Book a demo'>Book a demo</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 col-12 offset-md-2 pt-0'>
                            <p className='text-center'>
                                <small>
                                    Having any issues? Contact{' '}
                                    <a
                                        className='link-text'
                                        href='mailto:info@lenses.io'
                                        target='_blank'
                                        rel='noopener noreferrer'>
                                        info@lenses.io
                                    </a>
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/*<section>*/}
            {/*  <div className="container-1 pb-5">*/}
            {/*    <div className="row  mt-5 mb-5">*/}
            {/*      <div className="col-md-12 col-11 mobile-padding-reset">*/}
            {/*        <h2 className="paragraph-title mobile-text-center">Need help?</h2>*/}
            {/*      </div>*/}
            {/*      <div className="col-md-4 col-12">*/}
            {/*        <div className="shadow p-5">*/}
            {/*          <i*/}
            {/*            className="fa fa-file-text-o single-icon fa-2x"*/}
            {/*            aria-hidden="true"*/}
            {/*          ></i>*/}
            {/*          <p className="mt-3">*/}
            {/*            See our documentation on how to get started with Lenses Box*/}
            {/*          </p>*/}
            {/*          <a*/}
            {/*            className="link-text f-16"*/}
            {/*            href="/apache-kafka-docker/"*/}
            {/*            role="button"*/}
            {/*          >*/}
            {/*            Quick Start →*/}
            {/*          </a>*/}
            {/*        </div>*/}
            {/*      </div>*/}

            {/*      <div className="col-md-4 col-12">*/}
            {/*        <div className="p-5">*/}
            {/*          <i*/}
            {/*            className="fa fa-slack single-icon fa-2x"*/}
            {/*            aria-hidden="true"*/}
            {/*          ></i>*/}
            {/*          <p className="mt-3">*/}
            {/*            Speak to us directly on our Community Slack Channel*/}
            {/*          </p>*/}
            {/*          <a*/}
            {/*            className="link-text f-16"*/}
            {/*            href="https://launchpass.com/lensesio"*/}
            {/*            target="_blank"*/}
            {/*            rel="noopener noreferrer"*/}
            {/*            role="button"*/}
            {/*          >*/}
            {/*            Join now →*/}
            {/*          </a>*/}
            {/*        </div>*/}
            {/*      </div>*/}

            {/*      <div className="col-md-4 col-12">*/}
            {/*        <div className=" p-5">*/}
            {/*          <i*/}
            {/*            className="fa fa-send-o single-icon fa-2x"*/}
            {/*            aria-hidden="true"*/}
            {/*          ></i>*/}
            {/*          <p className="mt-3">*/}
            {/*            Send us a message <br />*/}
            {/*            <br />*/}
            {/*          </p>*/}
            {/*          <a*/}
            {/*            className="link-text f-16"*/}
            {/*            href="/contact-us/?s=thank-you-box"*/}
            {/*            role="button"*/}
            {/*          >*/}
            {/*            Contact →*/}
            {/*          </a>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</section>*/}

            {/*<section>*/}
            {/*  <div className="container-1 pb-5 mobile-padding-reset">*/}
            {/*    <div className="row mb-5 mobile-text-center mobile-padding-reset">*/}
            {/*      <div className="col-md-12 col-12 mobile-padding-reset">*/}
            {/*        <h2 className="paragraph-title">*/}
            {/*          Want connect your Kafka instead?*/}
            {/*        </h2>*/}
            {/*      </div>*/}

            {/*      <div className="col-md-6 col-12 mobile-padding-reset">*/}
            {/*        <p className="mt-3">*/}
            {/*          Run Lenses as a JVM application running in a virtual machine or*/}
            {/*          physical hardware, as a linux archive, container or cloud.*/}
            {/*        </p>*/}
            {/*        <a*/}
            {/*          className="link-text f-16"*/}
            {/*          href="/downloads/lenses-enterprise/?s=other"*/}
            {/*          role="button"*/}
            {/*        >*/}
            {/*          Request Now →*/}
            {/*        </a>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</section>*/}
        </Layout>
    )
}

export default lensesDownloadOk
